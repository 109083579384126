import { createSlice } from "@reduxjs/toolkit";

/**USER DETAILS SLICE */
const initialState = {
  walletAddress: "",
  socketReceive: 0,
  refreshUserData: "",
  supplyApyDetails: [],
  borrowApyDetails: []
};

export const UserSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    setWalletAddress: (state, param) => {
      const { payload } = param;
      state.walletAddress = payload;
    },
    setSocketReceive: (state, param) => {
      const { payload } = param;
      state.socketReceive = payload;
    },
    refreshUserData: (state, param) => {
      const { payload } = param;
      state.refreshUserData = payload;
    },
    setSupplyApyDetails: (state, param) => {
      const { payload } = param;
      state.supplyApyDetails = payload;
    },
    setBorrowApyDetails: (state, param) => {
      const { payload } = param;
      state.borrowApyDetails = payload;
    },
    logoutUser: () => initialState,
  },
});

/**ACTIONS FOR SLICE*/
// eslint-disable-next-line import/no-unused-modules
export const {
  setWalletAddress,
  setSocketReceive,
  refreshUserData,
  setSupplyApyDetails,
  setBorrowApyDetails,
  logoutUser,
} = UserSlice.actions;
