import { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { callApiGetMethod } from "../../../redux/Actions/api.action";
import { getLiquidateAccountDetails } from "../../../services/contract.service";
import { TABLE_LIMIT } from "../../../utils/Utils";
import CommonSearch from "../../common/CommonSearch/CommonSearch";
import "../../common/adminHeader/AdminHeader.scss";
import CommonHeading from "../../common/commonHeading/CommonHeading";
import CommonTable from "../../common/commonTable/CommonTable";
import CustomPagination from "../../common/customPagination/CustomPagination";
import BorrowersModal from "../../common/modals/borrowersModal/BorrowersModal";
import "./BorrowerList.scss";

import { loader } from "../../../redux/Slices/loader.slice";
import toaster from "../../common/toast";
import useCopyClipboard from "../../../hooks/useCopyToClipboard";
import { CopyIcon } from "../../../assets/icons/Icons";

const BorrowerList = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [borrower, setBorrowers] = useState<any>([]);
  const [userAddress, setUserAddress] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState<number>(0);
  const adminThead = [
    { label: "Sr. No" },
    { label: "Wallet" },
    { label: "Action" },
  ];
  const [setCopied] = useCopyClipboard();

  const getBorrowerAddress = useCallback(
    async (page: number = 1) => {
      try {
        let obj: {
          page: number;
          limit?: number;
          userAddress?: string;
        } = {
          page: page,
          limit: TABLE_LIMIT,
        };

        if (userAddress) {
          obj.userAddress = userAddress.trim();
        }
        dispatch(loader(true));

        let result: any = await dispatch(
          callApiGetMethod("GET_BORROW_LIST", obj, false)
        );
        if (result.success) {
          let data = await getLiquidateAccountDetails(result?.data?.data);

          setBorrowers(data);
          setCurrentPage(result?.data?.currentPage);
          setTotalData(result?.data?.totalCount);
          dispatch(loader(false));
        }
      } catch (error) {
        dispatch(loader(false));

        console.log("Error occured in getting borrow wallet address", error);
      } finally {
        dispatch(loader(false));
      }
    },
    [dispatch, userAddress]
  );

  useEffect(() => {
    getBorrowerAddress();
  }, [getBorrowerAddress]);

  const onPageChanged = useCallback(
    (page: number) => {
      getBorrowerAddress(page);
    },
    [getBorrowerAddress]
  );

  const handleCopy = (e: any, data: string, msg: string) => {
    e.preventDefault();
    setCopied(data);
    toaster.success(`${msg} Copied Successfully`);
  };

  return (
    <div className="table_sec">
      <div className="lending_currency_head">
        <CommonHeading title="Borrowers" />

        <CommonSearch
          placeholder="Search"
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUserAddress(e.target.value)
          }
        />
      </div>

      <CommonTable tableTitle={"Borrowers"} fields={adminThead}>
        {borrower &&
          borrower?.map((item: any, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>

              <td>
                {item?.userAddress}
                <button
                  className="copy_btn"
                  onClick={(e) => handleCopy(e, item?.userAddress, "Address")}
                >
                  <CopyIcon />
                </button>
              </td>

              <td>
                <BorrowersModal data={item} title="Liquidate" />
              </td>
            </tr>
          ))}
      </CommonTable>
      {totalData > TABLE_LIMIT && (
        <div className="pegination_box">
          <CustomPagination
            activePage={currentPage}
            itemsCountPerPage={TABLE_LIMIT}
            totalItemsCount={totalData}
            pageRangeDisplayed={2}
            onChange={onPageChanged}
          />
        </div>
      )}
    </div>
  );
};

export default BorrowerList;
