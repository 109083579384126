import "./Shimmer.scss";

type TProps = {
  animationDuration?: string,
  width?: number | string,
  height?: number | string,
  fluid?: boolean,
  verticlyFluid?: boolean,
  className?: string,
}

const Shimmer = ({
  animationDuration,
  width,
  height,
  fluid,
  verticlyFluid,
  className,
}: TProps) => {
  return (
    <span
      style={{
        width: fluid ? "100%" : width ? `${width}px` : "400px",
        height: verticlyFluid ? "100%" : height ? `${height}px` : "40px",
        animationDuration: animationDuration || "10s",
      }}
      className={`shimmer ${className || ""}`}
    />
  );
};

export default Shimmer;
