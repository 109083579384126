import { useFormik } from "formik";
import { Dispatch, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import {
  callContractGetMethod,
  callContractSendMethod,
  validateAddress,
} from "../../../redux/Actions/contract.action";
import {
  convertWithDecimal,
  divideBigNumberWithSuffixes,
  getFailureErrorMsg,
  handleBigNumbers
} from "../../../services/common.service";
import { RootState } from "../../../Types/reduxStateType/RootState";
import { Yup } from "../../../utils/Utils";
import "../../common/adminHeader/AdminHeader.scss";
import Button from "../../common/button/Button";
import Checkbox from "../../common/checkbox/Checkbox";
import CommonHeading from "../../common/commonHeading/CommonHeading";
import InputCustom from "../../common/commonInputs/InputCustom";
import ConfirmTransactionModal from "../../common/modals/confirmTransactionModal/ConfirmTransactionModal";
import toaster from "../../common/toast";
import "./Setting.scss";
const Setting = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");

  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [transferPauseStatus, setTransferPauseStatus] =
    useState<boolean>(false);
  const [seizePauseStatus, setSeizePauseStatus] = useState<boolean>(false);
  const [closeFactorValue, setCloseFactorValue] = useState<number>(0);
  const [incentive, setIncentive] = useState<number>(0);
  const [oracleAddress, setOracleAddress] = useState<string>("");

  const getTransferPauseStatus = async () => {
    try {
      const result: any = await dispatch(
        callContractGetMethod(
          "transferGuardianPaused",
          [],
          "comptroller",
          false
        )
      );
      if (result) {
        setTransferPauseStatus(result);
      }
    } catch (error) {
      console.log("Errror occurred in getting transfer pause status", error);
    }
  };

  const getSeizePauseStatus = async () => {
    try {
      const result: any = await dispatch(
        callContractGetMethod("seizeGuardianPaused", [], "comptroller", false)
      );
      if (result) {
        setSeizePauseStatus(result);
      }
    } catch (error) {
      console.log("Error occurred in getting transfer pause status", error);
    }
  };

  useEffect(() => {
    getTransferPauseStatus();
    getSeizePauseStatus();
    getCloseFactorMantissa();
    getOracle();
    getLiquidationIncentiveMantissa();
  }, []);

  // oracle

  const getOracle = async () => {
    let result: any = await dispatch(
      callContractGetMethod("oracle", [], "comptroller", false)
    );
    if (result) {
      setOracleAddress(result);
      formik.setValues({ newOracleAddress: result });
    }
  };

  const priceOracleInitialValues = {
    newOracleAddress: "",
  };
  const priceOracleSchema = Yup.object({
    newOracleAddress: Yup.string().required("Required Field*"),
  });
  const setNewPriceOracle = async (values: any) => {
    const isValidAddress: any = await dispatch(
      validateAddress(values.newOracleAddress, "contract")
    );
    if (!isValidAddress) {
      toaster.error("Please enter a valid address");
      return;
    }
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("oracle");

    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setPriceOracle",
        [values.newOracleAddress],
        walletAddress,
        "comptroller",
        "oracle"
      )
    );
    if (result?.status) {
      // handleClose()
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        setModalKey("error");

        toaster.error(errorMSg);
      } else {
        toaster.success("Price Oracle address updated successfully");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };
  const formik = useFormik({
    initialValues: priceOracleInitialValues,
    validationSchema: priceOracleSchema,
    onSubmit: setNewPriceOracle,
  });
  //closeFactorMantissa

  const getCloseFactorMantissa = async () => {
    let result: any = await dispatch(
      callContractGetMethod("closeFactorMantissa", [], "comptroller", false)
    );
    if (result) {
      setCloseFactorValue(divideBigNumberWithSuffixes(result, 16, true));
      formik2.setValues({
        closeFactor: divideBigNumberWithSuffixes(result, 16, true),
      });
    }
  };

  const closeFactorInitialValues = {
    closeFactor: 0,
  };
  const closeFactorSchema = Yup.object({
    closeFactor: Yup.number()
      .required("Required Field*")
      .min(0, "Value should not be lesser than 0")
      .max(100, "Value should not be greater than 100"),
  });
  const setCloseFactor = async (values: any) => {
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("closeFactor");
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setCloseFactor",
        [convertWithDecimal(values.closeFactor, 16)],
        walletAddress,
        "comptroller",
        "closeFactor"
      )
    );
    if (result?.status) {
      // handleClose()
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        setModalKey("error");
        toaster.error(errorMSg);
      } else {
        toaster.success("Close Factor updated successfully");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };
  const formik2 = useFormik({
    initialValues: closeFactorInitialValues,
    validationSchema: closeFactorSchema,
    onSubmit: setCloseFactor,
  });

  const getLiquidationIncentiveMantissa = async () => {
    let result: any = await dispatch(
      callContractGetMethod(
        "liquidationIncentiveMantissa",
        [],
        "comptroller",
        false
      )
    );
    if (result) {
      let latestVal = handleBigNumbers(
        result,
        convertWithDecimal(1, 18),
        "sub"
      );

      formik3.setValues({
        newLiquidationIncentive: divideBigNumberWithSuffixes(
          latestVal,
          16,
          true
        ),
      });
      setIncentive(divideBigNumberWithSuffixes(latestVal, 16, true));
    }
  };
  const liquidationIncentiveInitialValues = {
    newLiquidationIncentive: 0,
  };
  const liquidationIncentiveSchema = Yup.object({
    newLiquidationIncentive: Yup.number()
      .required("Required Field*")
      .min(0, "Value should be lesser than 0")
      .max(100, "Value should not be greater than 100"),
  });
  const setLiquidationIncentive = async (values: any) => {
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("incentive");
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setLiquidationIncentive",
        [
          handleBigNumbers(
            convertWithDecimal(1, 18),
            convertWithDecimal(values.newLiquidationIncentive, 16)
          ),
        ],
        walletAddress,
        "comptroller",
        "incentive"
      )
    );
    if (result?.status) {
      // handleClose()
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        setModalKey("error");
        toaster.error(errorMSg);
      } else {
        toaster.success("Liquidation Incentive updated successfully");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };
  const formik3 = useFormik({
    initialValues: liquidationIncentiveInitialValues,
    validationSchema: liquidationIncentiveSchema,
    onSubmit: setLiquidationIncentive,
  });

  const setTransferPaused = async () => {
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("pause");
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setTransferPaused",
        [!transferPauseStatus],
        walletAddress,
        "comptroller",
        "pause"
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        setModalKey("error");
        toaster.error(errorMSg);
      } else {
        setTimeout(() => {
          getTransferPauseStatus();
        }, 1000);
        toaster.success(`Transfer pause ${!transferPauseStatus?"enabled":"disabled"} successfully`);
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };
// seizePauseStatus
  const setSeizePaused = async () => {
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("pause");
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setSeizePaused",
        [!seizePauseStatus],
        walletAddress,
        "comptroller",
        "pause"
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        setModalKey("error");
        toaster.error(errorMSg);
      } else {
        setTimeout(() => {
          getSeizePauseStatus();
        }, 1000);
        toaster.success(`Seize pause ${!seizePauseStatus?"enabled":"disabled"} successfully`);

      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  return (
    <div className="setting">
      <CommonHeading title="Settings" />
      <>
        <div className="setting_in">
          <h3>Price Oracle</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className="gradient_box">
              <Row>
                <Col lg={6} xl={6} md={12} sm={6}>
                  <InputCustom
                    placeholder="Enter Price Oracle Address"
                    label="Price Oracle Address"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newOracleAddress}
                    maxLength={70}
                    name="newOracleAddress"
                    error={
                      <>
                        {formik.errors.newOracleAddress &&
                        formik.touched.newOracleAddress ? (
                          <>{formik.errors.newOracleAddress}</>
                        ) : null}
                      </>
                    }
                  />
                </Col>

                <Col lg={12}>
                  <Button
                    disabled={formik.values.newOracleAddress === oracleAddress}
                    className="submit_btn"
                    type="submit"
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </div>
          </form>
        </div>

        <div className="mid_setting">
          <Row>
            <Col lg={6} xl={4} md={12} sm={6}>
              <h3> Close Factor</h3>
              <div className="gradient_box">
                <form onSubmit={formik2.handleSubmit}>
                  <div className="field w-100">
                    <InputCustom
                      placeholder="Enter Close Factor Percentage"
                      label="Close Factor (%)"
                      min={0}
                      type="number"
                      maxLength={3}
                      onChange={formik2.handleChange}
                      onBlur={formik2.handleBlur}
                      name="closeFactor"
                      value={formik2.values.closeFactor}
                      error={
                        <>
                          {formik2.errors.closeFactor &&
                          formik2.touched.closeFactor ? (
                            <>{formik2.errors.closeFactor}</>
                          ) : null}
                        </>
                      }
                    />
                    <Button
                      disabled={
                        Number(closeFactorValue) ===
                        Number(formik2.values.closeFactor)
                      }
                      className="submit_btn"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </Col>
            <Col lg={6} xl={4} md={12} sm={6}>
              <h3>Liquidation Incentive</h3>
              <div className="gradient_box">
                <form onSubmit={formik3.handleSubmit}>
                  <div className="field w-100">
                    <InputCustom
                      placeholder="Enter Liquidation Incentive Percentage"
                      label="Liquidation Incentive (%)"
                      min={0}
                      type="number"
                      onChange={formik3.handleChange}
                      onBlur={formik3.handleBlur}
                      name="newLiquidationIncentive"
                      maxLength={3}
                      value={formik3.values.newLiquidationIncentive}
                      error={
                        <>
                          {formik3.errors.newLiquidationIncentive &&
                          formik3.touched.newLiquidationIncentive ? (
                            <>{formik3.errors.newLiquidationIncentive}</>
                          ) : null}
                        </>
                      }
                    />
                    <Button
                      disabled={
                        Number(incentive) ===
                        Number(formik3.values.newLiquidationIncentive)
                      }
                      className="submit_btn"
                      type="submit"
                    >
                      Update
                    </Button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <Col lg={6} xl={4} md={12} sm={6}>
            <div className="gradient_box">
              <div className="check_box">
                <h3>Transfer Pause</h3>
                <div className="field ">
                  <Checkbox
                    className="checkbox"
                    onChange={() => {
                      setTransferPaused();
                    }}
                    checked={transferPauseStatus}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} xl={4} md={12} sm={6}>
            <div className="gradient_box">
              <div className="check_box">
                <h3>Seize Pause</h3>
                <div className="field">
                  <Checkbox
                    className="checkbox"
                    onChange={() => {
                      setSeizePaused();
                    }}
                    checked={seizePauseStatus}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </div>
  );
};

export default Setting;
