import { Dispatch, useState, useCallback } from "react"; // Optimization: Added `useCallback` for memoizing functions
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { callContractSendMethod } from "../../../../redux/Actions/contract.action";
import { getFailureErrorMsg } from "../../../../services/common.service";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import Button from "../../button/Button";
import Checkbox from "../../checkbox/Checkbox";
import CommonModal from "../../commonModal/CommonModal";
import { default as toast, default as toaster } from "../../toast";
import "./BorrowPauseModal.scss";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";

const BorrowPauseModal = ({ data }: any) => {
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<string>(""); 
  const [show, setShow] = useState(false); 
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const setborrowPaused = useCallback(async () => {
    try {
      const provider = await connector?.getProvider();
      setShowConfirmation(true);
      setModalKey("borrowPaused");
      handleClose(); 
      const result: any = await dispatch(
        callContractSendMethod(
          provider,
          "_setBorrowPaused",
          [data.xToken, !data.borrowPauseState],
          walletAddress,
          "comptroller",
          "borrowPaused"
        )
      );

      if (result?.status) {
        if (result?.events?.Failure) {
          const errorMSg = getFailureErrorMsg(
            Number(result?.events?.Failure?.returnValues?.info)
          );
          toaster.error(errorMSg);
          setModalKey("error");
        } else {
          toast.success(
            `Borrow Pause ${
              !data?.borrowPauseState ? "Enabled" : "Disabled"
            } Successfully`
          );
        }
      } else if (result === undefined) {
        setModalKey("error");
      }
    } catch (error) {
      toaster.error("An error occurred during the transaction.");
    }
  }, [connector, data, dispatch, handleClose, walletAddress]); 

  return (
    <>
      <Checkbox
        className="checkbox"
        onChange={() => setShow(true)}
        checked={data?.borrowPauseState}
      />

      <CommonModal
        show={show}
        onHide={handleClose}
        title=""
        className="confirm_logout borrow_pause_modal"
      >
        <h4>{`Are you sure you want to ${
          data?.borrowPauseState ? "unpause" : "pause"
        } borrow for ${data?.name}?`}</h4>
        <div className="btn_pair">
          <Button onClick={setborrowPaused}>Confirm</Button>
          <Button onClick={handleClose} className="border_btn">
            Cancel
          </Button>
        </div>
      </CommonModal>

      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default BorrowPauseModal;
