import { ReactNode } from "react";
import "./CommonHeading.scss";
type TProps = {
  children?: ReactNode;
  title?: string;
  className?: string;
};

const CommonHeading = (props: TProps) => {
  return (
    <div className={`page_title ${props.className || ""}`}>
      <h2>{props.title || props.children}</h2>
    </div>
  );
};
export default CommonHeading;
