import "./DashboardCard.scss";

const DashboardCard = ({
  title,
  subTitle,
  icon,
}: {
  icon?: any;
  title?: string;
  subTitle?: string;
}) => {
  return (
    <div className="main_card">
      <div className="card_left">
        <span>{icon}</span>
        <div className="card_info">
          <p>{title}</p>
          <h6>{subTitle}</h6>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
