import { ButtonHTMLAttributes } from "react";
import "./Button.scss";
import { RootState } from "../../../Types/reduxStateType/RootState";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

type propTypes = ButtonHTMLAttributes<HTMLButtonElement> & {
    fluid?: boolean,
    loading?: boolean;
    text?: string;
    buttonLoader?: any;
}

const Button = (props: propTypes) => {
    const { loading, fluid, children, text, buttonLoader, className, ...rest } =props;
  const loadingStates = useSelector(
    (state: RootState) => state?.loader.buttonLoaderDetails
  );

    return (
        <button
          type="button"
          {...rest}
          className={`custom_btn ${fluid ? "w-100" : ""} ${className || ""} ${props?.disabled ? "disabled" : ""} ${loadingStates && loadingStates[props?.buttonLoader] ? 'not-allowed' : ''}`}
        >
          {buttonLoader && loadingStates[buttonLoader] ? "Processing..." : text || children}
          {buttonLoader && loadingStates[buttonLoader] ? (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          ) : (
            ""
          )}
        </button>
      );
}

export default Button
