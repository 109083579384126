import { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { callContractSendMethod } from "../../../../redux/Actions/contract.action";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import Button from "../../button/Button";
import CommonModal from "../../commonModal/CommonModal";
import toast from "../../toast";
import "./MarketListedModal.scss";
import { getFailureErrorMsg } from "../../../../services/common.service";
import toaster from "../../toast";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";

const MarketListedModal = ({ data }: any) => {
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");
  const [show, setShow] = useState<boolean>(false);
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();

  const handleClose = () => {
    setShow(false);
  };
  const supportMarket = async (newPairAddress: string) => {
    let provider = await connector?.getProvider();
      setShowConfirmation(true);
      setModalKey("market");
      handleClose()
      let result: any = await dispatch(
        callContractSendMethod(
          provider,
          "_supportMarket",
          [newPairAddress],
          walletAddress,
          "comptroller",
          "market"
        )
      );
      if (result?.status) {
        if (result?.events?.Failure) {
          let errorMSg = getFailureErrorMsg(
            Number(result?.events?.Failure?.returnValues?.info)
          );
          toaster.error(errorMSg);
          setModalKey("error");
        } else {
          toast.success("Transaction successfull");
        }
      } else if (result === undefined) {
        setModalKey("error");
      }
    
  };
  return (
    <>
      {" "}
      <Button className="setCollateralBtn" onClick={() => setShow(true)}>
        List
      </Button>
      <CommonModal
        show={show}
        onHide={handleClose}
        title="Set Market Listed true"
        className="confirm_logout"
        size={"lg"}
      >
        <h4>{`Are you sure you want to set market listed true?`}</h4>

        <div className="btn_pair">
          <Button onClick={() => supportMarket(data.xToken)}>Confirm</Button>
          <Button onClick={handleClose} className="border_btn">
            Cancel
          </Button>
        </div>
      </CommonModal>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default MarketListedModal;
