import { useFormik } from "formik";
import { Dispatch, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { RedirectIcon } from "../../../../assets/icons/Icons";
import { callApiGetMethod } from "../../../../redux/Actions/api.action";
import {
  callContractSendMethod,
  validateAddress,
} from "../../../../redux/Actions/contract.action";
import {
  allowOnlyNumberWithDecimalsInput,
  convertWithDecimal,
  getFailureErrorMsg,
} from "../../../../services/common.service";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import { Yup } from "../../../../utils/Utils";
import Button from "../../button/Button";
import InputCustom from "../../commonInputs/InputCustom";
import CommonModal from "../../commonModal/CommonModal";
import CustomSelect from "../../customSelect/CustomSelect";
import { default as toast, default as toaster } from "../../toast";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";
import "./AddCurrencyModal.scss";

const AddCurrencyModal = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const xTokenDecimals = useSelector(
    (state: RootState) => state.token.xTokenDecimals
  );
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [tokenData, setTokenData] = useState<any>("");
  const { connector } = useAccount();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");
  const [selectVal, setSelectVal] = useState<string>("");
  const formik = useFormik({
    initialValues: {
      address: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      const isValidAddress: any = await dispatch(
        validateAddress(values.address, "contract")
      );
      if (isValidAddress) {
        getTokenInformation(values.address);
      } else {
        setLoading(false);
        toast.error("Please enter a valid token address");
      }
    },
  });

  const handleAmountchange = (e: any, updateName: string) => {
    let value = e.target.value;
    if (value.startsWith(".")) {
      value = `0${value}`;
    }
    let valid = allowOnlyNumberWithDecimalsInput(value, 2);
    valid &&
      formik2.setValues({
        ...formik2.values,
        [updateName]: value,
      });
  };

  const jrmSchema = Yup.object().shape({
    initialExchangeRateMantissa: Yup.number()
      .required("Required Field*")
      .moreThan(0, "Converstion Rate must be greater than 0"),

    baseRatePerYear: Yup.number()
      .moreThan(0, "Base Rate Per Year must be greater than 0")
      .max(10, "Value must not be greater than 10")
      .required("Required Field*"),

    multiplierPerYear: Yup.number()
      .moreThan(0, "Multiplier Per Year must be greater than 0")
      .max(100, "Value must not be greater than 100 %")
      .required("Required Field*"),

    jumpMultiplierPerYear: Yup.number()
      .required("Required Field*")
      .moreThan(0, "Jump Multiplier Per Year must be greater than 0")
      .max(100, "Value must not be greater than 100 %"),

    kink: Yup.number()
      .required("Required Field*")
      .moreThan(0, "Kink value must be greater than 0")
      .max(100, "Value must not be greater than 100 %"),

    interestRateModel: Yup.string().required("Required*"),
  });

  const wrmSchema = Yup.object().shape({
    initialExchangeRateMantissa: Yup.number()
      .moreThan(0, "Converstion Rate must be greater than 0")
      .required("Required Field*"),

    baseRatePerYear: Yup.number()
      .moreThan(0, "Base Rate Per Year must be greater than 0")
      .max(10, "Value must not be greater than 10")
      .required("Required Field*"),

    multiplierPerYear: Yup.number()
      .moreThan(0, "Multiplier Per Year must be greater than 0")
      .max(100, "Value must not be greater than 100 %")
      .required("Required Field*"),

    interestRateModel: Yup.string().required("Required*"),
  });

  const formik2 = useFormik({
    initialValues: {
      initialExchangeRateMantissa: "",
      baseRatePerYear: "",
      multiplierPerYear: "",
      jumpMultiplierPerYear: "",
      kink: "",
      interestRateModel: "",
    },
    validationSchema: selectVal === "WRM" ? wrmSchema : jrmSchema,
    onSubmit: async (values) => {
      createNewPairs(values);
    },
  });
  const getTokenInformation = async (_address: string) => {
    try {
      let obj: {
        tokenAddress: string;
      } = {
        tokenAddress: _address,
      };
      let result: any = await dispatch(
        callApiGetMethod("GET_TOKEN_INFO", obj, false)
      );
      if (result?.success) {
        setTokenData(result?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error occured in getting token information", error);
    }
  };

  const createNewPairs = async (values: any) => {
    let randomByteVal = numberTo32Bytes();
    let provider = await connector?.getProvider();

    if (tokenData) {
      setShowConfirmation(true);
      setModalKey("createPair");
      handleClose();
      let result: any = await dispatch(
        callContractSendMethod(
          provider,
          "createNewPair",
          [
            convertWithDecimal(
              1 / values.initialExchangeRateMantissa,
              tokenData?.decimals + 10
            ),
            convertWithDecimal(values.baseRatePerYear, 16),
            convertWithDecimal(values.multiplierPerYear, 16),
            selectVal === "JRM"
              ? convertWithDecimal(values.jumpMultiplierPerYear, 16)
              : 0,
            selectVal === "JRM" ? convertWithDecimal(values.kink, 16) : 0,
            xTokenDecimals,
            tokenData?.tokenAddress,
            `x${tokenData?.name}`,
            `x${tokenData?.symbol}`,
            randomByteVal,
          ],
          walletAddress,
          "factory",
          "createPair"
        )
      );

      if (result?.status) {
        if (result?.events?.Paircreated) {
          supportMarket(result?.events?.Paircreated?.returnValues.xToken);
        }
      } else if (result === undefined) {
        setModalKey("error");
      }
    } else {
      toast.error("Some error in Function calling");
    }
  };

  const supportMarket = async (newPairAddress: string) => {
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("createPair");
    handleClose();

    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_supportMarket",
        [newPairAddress],
        walletAddress,
        "comptroller",
        "createPair"
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        toaster.error(errorMSg);
        setModalKey("error");
      } else {
        toast.success("Transaction successfull");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setSelectVal("");
    setShow(false);
    formik.resetForm();
    formik2.resetForm();
    setTokenData("");
    setLoading(false);
  };

  function numberTo32Bytes(): string {
    let hex = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(16);
    let paddedHex = hex.padEnd(64, "0");
    return "0x" + paddedHex;
  }
  const optionsArray = [
    {
      label: "Jumprate Module",
      value: "JRM",
    },
    {
      label: "Whitepaper Module",
      value: "WRM",
    },
  ];

  return (
    <>
      <Button onClick={handleShow}>Add Currencies</Button>
      <CommonModal
        show={show}
        onHide={handleClose}
        title={"Add Currencies"}
        className="add_currency_modal"
      >
        <form className="address_form" onSubmit={formik.handleSubmit}>
          <>
            <InputCustom
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              placeholder={"Enter Token Address"}
              label={"Address"}
              name="address"
              maxLength={45}
              disabled={tokenData}
            />

            {!tokenData && (
              <Button className="address_btn" type="submit">
                Fetch Data
              </Button>
            )}
          </>
        </form>

        {loading && (
          <div className="spinner_box">
            <Spinner />
          </div>
        )}
        {tokenData && (
          <>
            <ul>
              <li>
                <img src={tokenData?.logo} alt="" />
                {tokenData?.symbol}
              </li>
              <li>{tokenData?.name}</li>
              <li>
                <a
                  href={tokenData?.urls?.explorer[2]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <RedirectIcon />
                </a>
              </li>
            </ul>

            <form className="address_form" onSubmit={formik2.handleSubmit}>
              <>
                <InputCustom
                  onChange={(e: any) =>
                    handleAmountchange(e, "initialExchangeRateMantissa")
                  }
                  onBlur={formik2.handleBlur}
                  value={formik2.values.initialExchangeRateMantissa}
                  placeholder={"Enter Token conversion rate"}
                  label={"Token conversion rate"}
                  name="initialExchangeRateMantissa"
                  min={0}
                  type="text"
                  maxLength={10}
                  error={
                    formik2.touched.initialExchangeRateMantissa &&
                    formik2.errors.initialExchangeRateMantissa
                  }
                />
                <CustomSelect
                  label=" Select Interest rate"
                  onChange={(option: any) => {
                    formik2.values.interestRateModel = option.value;
                    setSelectVal(option.value);
                  }}
                  onBlur={formik2.handleBlur}
                  value={formik2.values.interestRateModel}
                  name="interestRateModel"
                  options={optionsArray}
                  error={
                    formik2.touched.interestRateModel &&
                    formik2.errors.interestRateModel
                  }
                />
                <InputCustom
                  onChange={(e: any) =>
                    handleAmountchange(e, "baseRatePerYear")
                  }
                  onBlur={formik2.handleBlur}
                  value={formik2.values.baseRatePerYear}
                  placeholder={"Enter Base Rate"}
                  label={"Base Rate Per Year"}
                  name="baseRatePerYear"
                  type="text"
                  maxLength={4}
                  min={0}
                  error={
                    formik2.touched.baseRatePerYear &&
                    formik2.errors.baseRatePerYear
                  }
                />
                <InputCustom
                  onChange={(e: any) =>
                    handleAmountchange(e, "multiplierPerYear")
                  }
                  onBlur={formik2.handleBlur}
                  value={formik2.values.multiplierPerYear}
                  placeholder={"Multiplier Per Year"}
                  label={"Multiplier Per Year"}
                  name="multiplierPerYear"
                  min={0}
                  type="text"
                  maxLength={5}
                  error={
                    formik2.touched.multiplierPerYear &&
                    formik2.errors.multiplierPerYear
                  }
                />
                {formik2.values.interestRateModel === "JRM" && (
                  <>
                    <InputCustom
                      onChange={(e: any) =>
                        handleAmountchange(e, "jumpMultiplierPerYear")
                      }
                      onBlur={formik2.handleBlur}
                      value={formik2.values.jumpMultiplierPerYear}
                      placeholder={"Jump Multiplier Per Year"}
                      label={"Jump Multiplier Per Year"}
                      name="jumpMultiplierPerYear"
                      min={0}
                      type="text"
                      maxLength={5}
                      error={
                        formik2.touched.jumpMultiplierPerYear &&
                        formik2.errors.jumpMultiplierPerYear
                      }
                    />
                    <InputCustom
                      onChange={(e: any) => handleAmountchange(e, "kink")}
                      onBlur={formik2.handleBlur}
                      value={formik2.values.kink}
                      placeholder={"kink"}
                      label={"Kink"}
                      name="kink"
                      min={0}
                      type="text"
                      maxLength={5}
                      error={formik2.touched.kink && formik2.errors.kink}
                    />
                  </>
                )}
                <InputCustom
                  value={`x${tokenData?.name}`}
                  placeholder={"Enter Name"}
                  label={"Name"}
                  name="_name"
                  disabled
                />
                <InputCustom
                  value={`x${tokenData?.symbol}`}
                  placeholder={"Enter Symbol"}
                  label={"Symbol"}
                  name="_symbol"
                  disabled
                />
                <Button className="address_btn" type="submit">
                  Submit
                </Button>
              </>
            </form>
          </>
        )}
      </CommonModal>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default AddCurrencyModal;
