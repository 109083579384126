import { Dispatch, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { callContractSendMethod } from "../../../../redux/Actions/contract.action";
import { getFailureErrorMsg } from "../../../../services/common.service";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import Button from "../../button/Button";
import Checkbox from "../../checkbox/Checkbox";
import CommonModal from "../../commonModal/CommonModal";
import { default as toast, default as toaster } from "../../toast";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";
import "./MintPauseModal.scss";

const MintPauseModal = ({ data }: any) => {
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<string>("");
  const [show, setShow] = useState(false); 
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const setMintPaused = useCallback(async () => {
    try {
      const provider = await connector?.getProvider();
      setShowConfirmation(true);
      setModalKey("mintPaused");
      handleClose(); 
      const result: any = await dispatch(
        callContractSendMethod(
          provider,
          "_setMintPaused",
          [data.xToken, !data.mintPauseState],
          walletAddress,
          "comptroller",
          "mintPaused"
        )
      );

      if (result?.status) {
        if (result?.events?.Failure) {
          const errorMsg = getFailureErrorMsg(
            Number(result?.events?.Failure?.returnValues?.info)
          );
          toaster.error(errorMsg);
          setModalKey("error");
        } else {
          toast.success(
            `Mint Pause ${
              data?.mintPauseState ? "Disabled" : "Enabled"
            } successfully.`
          );
        }
      } else if (result === undefined) {
        setModalKey("error");
      }
    } catch (error) {
      toaster.error("An error occurred during the transaction.");
    }
  }, [connector, data, dispatch, handleClose, walletAddress]); 

  return (
    <>
      <Checkbox
        className="checkbox"
        onChange={() => setShow(true)}
        checked={data?.mintPauseState}
      />

      <CommonModal
        show={show}
        onHide={handleClose}
        title=""
        className="confirm_logout mint_modal"
      >
        <h4>{`Are you sure you want to ${
          data?.mintPauseState ? "unpause" : "pause"
        } mint for ${data?.name}?`}</h4>
        <div className="btn_pair">
          <Button onClick={setMintPaused}>Confirm</Button>
          <Button onClick={handleClose} className="border_btn">
            Cancel
          </Button>
        </div>
      </CommonModal>

      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default MintPauseModal;
