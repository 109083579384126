import "./ErrorPage.scss";
import { NavLink } from "react-router-dom";
import { ErrorImg } from "../../../assets/icons/Icons";

const ErrorPage = () => {
  return (
    <div className="error_page">
      <ErrorImg />
      <h3>The page you are looking for can’t be found</h3>
      <NavLink to="/">Back To Home</NavLink>
    </div>
  );
};

export default ErrorPage;
