import { useSelector } from "react-redux";
import { RejectedIcon, SuccessIcon } from "../../../../assets/icons/Icons";
import Loading from "../../../../assets/images/loadingImage.png";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import CommonModal from "../../commonModal/CommonModal";
import "./ConfirmTransactionModal.scss";

const ConfirmTransactionModal = (props: any) => {
  const loadingStates = useSelector(
    (state: RootState) => state?.loader.buttonLoaderDetails
  );
  // const [show, setShow] = useState<boolean>(false);
  const onHide = () => {
    if (
      props.modalKey !== "error" &&
      props.modalKey &&
      loadingStates[props.modalKey]
    ) {
      return
      // setShow(true);
    } else {
      // setShow(false);
      props.onHide();
    }
  };
  return (
    <CommonModal
      show={props.show }
      onHide={onHide}
      backdrop={"static"}
      title={
        props.modalKey !== "error"
          ? props.modalKey && loadingStates[props.modalKey]
            ? "Confirm Transaction"
            : "Transaction Successfull"
          : "Error while processing"
      }
      className="confirm_transaction"
    >
      <div className="success_box">
        {props.modalKey !== "error" ? (
          props.modalKey && loadingStates[props.modalKey] ? (
            <>
              <div className="buffring_img">
                <img src={Loading} alt="" />
              </div>
              <p>Confirm the transaction with Metamask</p>
            </>
          ) : (
            <SuccessIcon />
          )
        ) : (
          <RejectedIcon />
        )}
      </div>
    </CommonModal>
  );
};

export default ConfirmTransactionModal;
