import Button from "../../button/Button";
import CommonModal from "../../commonModal/CommonModal";
import "./ConfirmationModal.scss";

const ConfirmationModal = (props: any) => {
  return (
    <CommonModal
      show={props.show}
      onHide={props.onHide}
      title="Confirm Logout"
      className="confirm_logout"
    >
      <h4>{props.content}</h4>
      <div className="btn_pair">
        <Button onClick={props.callback}>Confirm</Button>
        <Button onClick={props.onHide} className="border_btn">
          Cancel
        </Button>
      </div>
    </CommonModal>
  );
};

export default ConfirmationModal;
