import { Form } from "react-bootstrap";
import "./Checkbox.scss";

const Checkbox = (props:any) => {
  return (
    <div>
      <Form.Group controlId="custom-switch">
        <Form.Check
          type="switch"
          id="custom-switch"
          label={props.checkbox}
          className="custom_checkbox"
          onChange={props.onChange}
          checked={props.checked}
        />
      </Form.Group>
    </div>
  );
};

export default Checkbox;
  