import { Dispatch } from "@reduxjs/toolkit";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useAccount, useDisconnect, useSwitchChain } from "wagmi";
import toast from "./components/common/toast";
import AuthLayout from "./components/layouts/authLayout/AuthLayout";
import MainLayout from "./components/layouts/mainLayout/MainLayout";
import AdminDashboard from "./components/pages/adminDashboard/AdminDashboard";
import CurrencyList from "./components/pages/currencyList/CurrencyList";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Login from "./components/pages/login/Login";
import Setting from "./components/pages/setting/Setting";
import TransactionHistory from "./components/pages/transactionHistory/TransactionHistory";
import { RequireAuth } from "./Guard/AuthGuard";
import { setUsdDecimals, setXTokenDecimals } from "./redux/Slices/token.slice";
import { setWalletAddress } from "./redux/Slices/user.slice";
import { RootState } from "./Types/reduxStateType/RootState";
import { CHAIN_ID, NETWORK_NAME } from "./utils/Constant";
import { ROUTES } from "./utils/Utils";
import ErrorPage from "./components/common/ErrorPage/ErrorPage";
import BorrowerList from "./components/pages/borrowerList/BorrowerList";
import { LoginGaurd } from "./Guard/LoginGaurd";
import { RequireUserAuth } from "./Guard/UserAuthGuard";
import { setIsAdmin } from "./redux/Slices/admin.slice";
// import { ROUTES } from "./utils/Utils";

const Application = () => {
  const { address, isDisconnected } = useAccount();
  const dispatch: Dispatch<any> = useDispatch();
  const { switchChainAsync } = useSwitchChain();
  const { selectedNetworkId } = useWeb3ModalState();
  const { disconnect } = useDisconnect();
  const wallet = useSelector((state: RootState) => state.user.walletAddress);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: ROUTES.TRANSACTION,
          element: (
            <RequireUserAuth>
              <TransactionHistory />
            </RequireUserAuth>
          ),
        },
      ],
    },
    {
      path: ROUTES.LOGIN,
      element: (
        <LoginGaurd>
          <Login />
        </LoginGaurd>
      ),
    },
    {
      element: (
        <RequireAuth>
          <MainLayout />
        </RequireAuth>
      ),
      children: [
        {
          index: true,
          path: ROUTES.ADMIN_DASHBOARD,
          element: (
            <RequireAuth>
              <AdminDashboard />
            </RequireAuth>
          ),
        },

        {
          path: ROUTES.ADMIN_TRANSACTION,
          element: (
            <RequireAuth>
              <TransactionHistory />
            </RequireAuth>
          ),
        },
        {
          path: ROUTES.CURRENCY_LIST,
          element: (
            <RequireAuth>
              <CurrencyList />
            </RequireAuth>
          ),
        },
        {
          path: ROUTES.BORROWER_LIST,
          element: (
            <RequireAuth>
              {" "}
              <BorrowerList />
            </RequireAuth>
          ),
        },
        {
          path: ROUTES.SETTING,
          element: (
            <RequireAuth>
              <Setting />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]);

  useEffect(() => {
    dispatch(setUsdDecimals(18));
    dispatch(setXTokenDecimals(8));
  }, [dispatch]);

  // Memoize the disconnect function
  const memoizedDisconnect = useCallback(() => {
    dispatch(setWalletAddress(""));
    dispatch(setIsAdmin(false));
    disconnect();
  }, [disconnect, dispatch]);

  useEffect(() => {
    if (address) {
      // Debounce mechanism
      const debounceTimeout = setTimeout(() => {
        if (selectedNetworkId && Number(selectedNetworkId) !== CHAIN_ID) {
          toast.error(`Please connect to ${NETWORK_NAME} network`);
          switchChainAsync({ chainId: CHAIN_ID })
            .then((result) => {
              toast.success(`Network switched to ${NETWORK_NAME}`);
              console.log("result", result);
            })
            .catch((error: Error) => {
              // Use the memoized disconnect function
              memoizedDisconnect();
              console.log("error", error);
              window.location.reload();
            });
        } else if (address && Number(selectedNetworkId) === CHAIN_ID) {
          if (wallet === "") {
            console.log("Wallet connected");
          } else if (
            wallet !== address &&
            Number(selectedNetworkId) === CHAIN_ID
          ) {
            toast.success("Wallet changed");
            dispatch(setIsAdmin(false));
          }
          dispatch(setWalletAddress(address));
        }
      }, 1500);

      // Clear the debounce timeout to avoid executing the callback if the component unmounts
      return () => clearTimeout(debounceTimeout);
    }
  }, [wallet, address, selectedNetworkId]);

  useEffect(() => {
    if (isDisconnected) {
      setTimeout(() => {
        if (wallet) console.log("Wallet disconnected");
        memoizedDisconnect();
      }, 1000);
    }
  }, [isDisconnected]);

  return <RouterProvider router={router} />;
};

export default Application;
