import { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { LinesIcon } from "../../../assets/icons/Icons";
import minilogo from "../../../assets/logo/SmallLogo.png";
import Web3 from "web3";
import logo from "../../../assets/logo/logo.png";
import Button from "../button/Button";
import "./DashboardHeader.scss";
import ConnectWallet from "../connectWallet/ConnectWallet";
import { useSelector } from "react-redux";
import { RootState } from "../../../Types/reduxStateType/RootState";
import {
  divideBigNumber,
  fixedToDecimal,
} from "../../../services/common.service";
import { CURRENCY, RPC_URL } from "../../../utils/Constant";
import { ROUTES } from "../../../utils/Utils";

const DashboardHeader = () => {
  const { walletAddress } = useSelector((state: RootState) => state.user);
  const [open, setOpen] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0.0);
  const location = useLocation();

  const getUserBalance = useCallback(async () => {
    try {
      const balance = await new Web3(RPC_URL).eth.getBalance(walletAddress);
      setBalance(divideBigNumber(balance ?? "0", 18));
    } catch (error) {
      console.log("Error occured in getUserBalance", error);
    }
  }, [walletAddress]);

  useEffect(() => {
    walletAddress && getUserBalance();
  }, [walletAddress, getUserBalance]);

  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "";
  }, [open]);

  return (
    <header className="dashboard_header">
      <Container>
        <div className="header_in">
          <div className="logo_box">
            <Link to={"/"} className="logo">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className={`overlay ${open ? "active" : ""}`}></div>
          <ul className={open ? "active" : ""}>
            <li onClick={() => setOpen(!open)}>
              <Link
                to={""}
                className={"/" === location?.pathname ? "active" : ""}
              >
                Dashboard
              </Link>
            </li>
            {walletAddress && (
              <li onClick={() => setOpen(!open)}>
                <Link
                  to={ROUTES.TRANSACTION}
                  className={
                    ROUTES.TRANSACTION === location.pathname ? "active" : ""
                  }
                >
                  Txn. History
                </Link>
              </li>
            )}
            <div className="responsive_btn_box ">
              <Button className="light_bg_btn">
                <img src={minilogo} alt="metamask" />
                <span title={String(balance)}>
                  {fixedToDecimal(String(balance))} {CURRENCY}
                </span>
              </Button>
              <ConnectWallet />
            </div>
          </ul>

          <div className="btn_box ">
            <Button className="light_bg_btn">
              <img src={minilogo} alt="metamask" />
              <span title={String(balance)}>
                {fixedToDecimal(String(balance))} {CURRENCY}
              </span>
            </Button>
            <ConnectWallet />
          </div>
          <Button
            className={`light_bg_btn toggle_btn d-md-none ${
              open ? "active" : ""
            }`}
            onClick={() => setOpen(!open)}
          >
            <span></span>
            <span></span>
            <span></span>
          </Button>
        </div>
      </Container>
    </header>
  );
};

export default DashboardHeader;
