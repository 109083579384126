import { createSlice } from "@reduxjs/toolkit";

/**ADMIN DETAILS SLICE */
const initialState = {
  isAdmin: false,
};

export const AdminSlice = createSlice({
  name: "admin",
  initialState,

  reducers: {
    setIsAdmin: (state, param) => {
      const { payload } = param;
      state.isAdmin = payload;
    },
    logoutAdmin: () => initialState,
  },
});

/**ACTIONS FOR SLICE*/
// eslint-disable-next-line import/no-unused-modules
export const { setIsAdmin, logoutAdmin } = AdminSlice.actions;
