import { useState } from "react";
import { ThreeLines } from "../../../assets/icons/Icons";
import logo from "../../../assets/logo/logo.png";
import Button from "../button/Button";
import ConfirmationModal from "../modals/confirmationModal/ConfirmationModal";
import toast from "../toast";
import "./AdminHeader.scss";
import ConnectWallet from "../connectWallet/ConnectWallet";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { setIsAdmin } from "../../../redux/Slices/admin.slice";

export type HeaderProps = {
  handleToggle: () => void;
  show: boolean;
};

const AdminHeader = ({ handleToggle, show }: HeaderProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleLogout = () => {
    dispatch(setIsAdmin(false));
    toast.success("Log out successfull");
  };

  return (
    <>
      <header className="admin_header">
        <div className="admin_header_in">
          <img src={logo} alt="" className="d-md-none" />
          {/* <div className={`overlay ${handleToggle}`}></div> */}
          <div className="side_btns">
            <ConnectWallet />
            <Button
              onClick={() => setShowModal(true)}
              className="ms-auto header_btn"
            >
              Log Out
            </Button>
            <Button
              onClick={handleToggle}
              className={`ms-auto d-md-none sidebar_btn ${
                show ? "active" : ""
              }`}
            >
              <span></span>
              <span></span>
              <span></span>
            </Button>
          </div>
        </div>
      </header>
      <ConfirmationModal
        content={"Are you sure you want to logout?"}
        show={showModal}
        onHide={() => setShowModal(false)}
        callback={() => handleLogout()}
      />
    </>
  );
};

export default AdminHeader;
