import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../Types/reduxStateType/RootState";

/**AUTHGAURD FOR INNER PAGES */
export const RequireUserAuth = (props: any) => {
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  return walletAddress ? props?.children : <Navigate to="/" />;
};
