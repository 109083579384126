import { SearchIcon } from "../../../assets/icons/Icons";
import { allowOnlyString } from "../../../services/common.service";
import "./CommonSearch.scss";

const CommonSearch = (props: any) => {
  const disabledCharacters = ["e", "E", "+", "-"];
  const onKeyDown = (e: any) => {
    if (props.disableDecimal) {
      disabledCharacters.push(".");
    }

    /** RESTRICT USER TO ENTER MORE THEN MAX LENGTH IN INPUT TYPE NUBER */
    return props.type === "number"
      ? (disabledCharacters.includes(e.key) ||
          (e.key !== "Backspace" &&
            props.maxLength &&
            e.target.value.length === props.maxLength)) &&
          e.preventDefault()
      : props.onlyChar
      ? !allowOnlyString(e.key) && e.preventDefault()
      : null;
  };

  return (
    <>
      <div className="common_search">
        {props.label ? <label>{props.label}</label> : ""}
        <div className="common_search_in">
          <input
            type={props?.type}
            name={props.name}
            id={props.name}
            value={props?.value}
            onChange={props?.onChange}
            onKeyDown={onKeyDown}
            autoComplete="off"
            autoFocus={props?.autoFocus}
            maxLength={props?.maxLength}
            onFocus={props?.onFocus}
            placeholder={props?.placeholder}
          />
          <div className="search_icon">
            <SearchIcon />
          </div>
        </div>
        {props.error && <p className="error_msg">{props.error}</p>}
      </div>
    </>
  );
};

export default CommonSearch;
