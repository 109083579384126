import "./CustomPagination.scss";
import Pagination from "react-js-pagination";

const CustomPagination = (props: any) => {
  return (
    <div className="custom_pagination">
      <Pagination
        activePage={props?.activePage}
        itemsCountPerPage={props?.itemsCountPerPage}
        totalItemsCount={props?.totalItemsCount}
        hideFirstLastPages={false}
        pageRangeDisplayed={props?.pageRangeDisplayed}
        onChange={props?.onChange}
      />
    </div>
  );
};

export default CustomPagination;
