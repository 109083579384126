import { useFormik } from "formik";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import * as Yup from "yup";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../../redux/Actions/contract.action";
import {
  allowOnlyNumberWithDecimalsInput,
  convertWithDecimal,
  divideBigNumber,
  divideBigNumberWithSuffixes,
  handleBigNumbers,
} from "../../../../services/common.service";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import Button from "../../button/Button";
import InputCustom from "../../commonInputs/InputCustom";
import CommonModal from "../../commonModal/CommonModal";
import toast from "../../toast";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";
import "./InterestRateModal.scss";
import CustomSelect from "../../customSelect/CustomSelect";
interface propTypes {
  data?: any;
  title?: string;
}
const InterestRateModal = ({ data, title }: propTypes) => {
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [show, setShow] = useState(false);
  const [functionCall, setFunctionCall] = useState("");
  const [irmAddress, setIrmAddress] = useState("");

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();
  const [selectVal, setSelectVal] = useState<string>("JRM");
  const jrmSchema = Yup.object().shape({
    baseRatePerYear: Yup.number()
      .required("Required Field*")
      .max(10, "Value must not be greater than 10")
      .moreThan(0, "Value should be greater than 0"),
    multiplierPerYear: Yup.number()
      .required("Required Field*")
      .max(100, "Value must not be greater than 100 %")
      .moreThan(0, "Value should be greater than 0"),
    jumpMultiplierPerYear: Yup.number()
      .required("Required Field*")
      .max(100, "Value must not be greater than 100 %")
      .moreThan(0, "Value should be greater than 0"),
    kink: Yup.number()
      .required("Required Field*")
      .max(100, "Value must not be greater than 100 %")
      .moreThan(0, "Value should be greater than 0"),
    interestRateModel: Yup.string().required("Required Field*"),
  });

  const wrmSchema = Yup.object().shape({
    baseRatePerYear: Yup.number()
      .required("Required Field*")
      .max(10, "Value must not be greater than 10")
      .moreThan(0, "Value should be greater than 0"),
    multiplierPerYear: Yup.number()
      .required("Required Field*")
      .max(100, "Value must not be greater than 100 %")

      .moreThan(0, "Value should be greater than 0"),
    interestRateModel: Yup.string().required("Required Field*"),
  });
  const formik = useFormik({
    initialValues: {
      baseRatePerYear: "",
      multiplierPerYear: "",
      jumpMultiplierPerYear: "",
      kink: "",
      interestRateModel: "",
    },
    validationSchema: selectVal === "WRM" ? wrmSchema : jrmSchema,

    onSubmit: (values) => {
      if (functionCall === "create") {
        createInterestRateModel(values);
      } else if (functionCall === "update") {
      
          updateJRM(values);
        
      }
    },
  });

  const getIRMAddress = async (_add: string) => {
    let result: any = await dispatch(
      callContractGetMethod("interestRateModel", [], "cerc20", false, _add)
    );
    if (result) {
      setIrmAddress(result);
    }
  };

  const updateJRM = async (values: any) => {
    const uniqueValue = numberTo32Bytes();
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("reserveFactor");
    handleClose();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "updateJumpRateModel",
        [
          convertWithDecimal(values?.baseRatePerYear, 16),
          convertWithDecimal(values?.multiplierPerYear, 16),
          convertWithDecimal(values.jumpMultiplierPerYear, 16),
          convertWithDecimal(values.kink, 16),
          uniqueValue,
        ],
        walletAddress,
        "JRM",
        "reserveFactor",
        "",
        irmAddress
      )
    );
    if (result?.status) {
      toast.success("Transaction successfull");
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  const createInterestRateModel = async (values: any) => {
    const uniqueValue = numberTo32Bytes();
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("reserveFactor");
    handleClose();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "createInterestRateModel",
        [
          convertWithDecimal(values?.baseRatePerYear, 16),
          convertWithDecimal(values?.multiplierPerYear, 16),
          selectVal === "JRM"
            ? convertWithDecimal(values.jumpMultiplierPerYear, 16)
            : 0,
          selectVal === "JRM" ? convertWithDecimal(values.kink, 16) : 0,
          uniqueValue,
        ],
        walletAddress,
        "factory",
        "reserveFactor"
      )
    );
    if (result?.status) {
      if (result?.events?.InterestRateModelcreated) {
        setInterestRateModule(
          result.events.InterestRateModelcreated.returnValues[0]
        );
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };
  const setInterestRateModule = async (_contractAdd: string) => {
    setShowConfirmation(true);
    setModalKey("reserveFactor");
    let provider = await connector?.getProvider();

    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setInterestRateModel",
        [_contractAdd],
        walletAddress,
        "cerc20",
        "reserveFactor",
        "",
        data?.xToken
      )
    );
    if (result?.status) {
      toast.success("Transaction successfull");
    } else if (result === undefined) {
      setModalKey("error");
    }
  };
  function numberTo32Bytes(): string {
    let hex = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(16);
    let paddedHex = hex.padEnd(64, "0");
    return "0x" + paddedHex;
  }
  const handleClose = () => {
    setShow(false);
    setFunctionCall("");
    setIrmAddress("");
    setSelectVal("JRM");
    formik.resetForm();
  };
  useEffect(() => {
    if (show && functionCall === "update") {
      formik.setValues({
        ...formik.values,
        baseRatePerYear: divideBigNumberWithSuffixes(
          handleBigNumbers(data.baseRatePerBlock, data.blocksPerYear, "mul"),
          16,
          true
        ),
        multiplierPerYear: divideBigNumberWithSuffixes(
          divideBigNumber(
            handleBigNumbers(
              data.multiplierPerBlock,
              handleBigNumbers(data.kink, data.blocksPerYear, "mul"),
              "mul"
            ),
            18
          ),
          16,
          true
        ),

        jumpMultiplierPerYear: divideBigNumberWithSuffixes(
          handleBigNumbers(
            data.jumpMultiplierPerBlock,
            data.blocksPerYear,
            "mul"
          ),
          16,
          true
        ),

        kink: divideBigNumberWithSuffixes(data.kink, 16, true),
      });
    } else {
      formik.resetForm();
    }
  }, [show]);

  const handleShow = (msg: string, val: boolean) => {
    setShow(val);
    setFunctionCall(msg);
    if (msg === "update") {
      getIRMAddress(data?.xToken);
      formik.setValues({
        ...formik.values,
        interestRateModel: "JRM",
      });
    }
  };
  const optionsArray = [
    {
      label: "Jumprate Module",
      value: "JRM",
    },
    {
      label: "Whitepaper Module",
      value: "WRM",
    },
  ];

  const handleAmountchange = (e: any, updateName: string) => {
    let value = e.target.value;
    if (value.startsWith(".")) {
      value = `0${value}`;
    }
    let valid = allowOnlyNumberWithDecimalsInput(value, 2);
    valid &&
      formik.setValues({
        ...formik.values,
        [updateName]: value,
      });
  };
  return (
    <>
      <Button
        className="setCollateralBtn"
        onClick={() => handleShow("create", true)}
      >
        Create
      </Button>
      {data.jumpMultiplierPerBlock && data.kink && (
        <Button
          className="setCollateralBtn"
          onClick={() => handleShow("update", true)}
        >
          Update
        </Button>
      )}
      <CommonModal
        show={show}
        onHide={handleClose}
        title={title}
        className="confirm_logout"
        size="sm"
      >
        {/* <h4>{props.content}</h4> */}
        <form onSubmit={formik.handleSubmit}>
          {functionCall === "create" && (
            <CustomSelect
              label=" Select Interest rate"
              onChange={(option: any) => {
                formik.values.interestRateModel = option.value;
                setSelectVal(option.value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.interestRateModel}
              name="interestRateModel"
              options={optionsArray}
              error={
                formik.touched.interestRateModel &&
                formik.errors.interestRateModel
              }
            />
          )}

          <InputCustom
            onChange={(e: any) => handleAmountchange(e, "baseRatePerYear")}
            onBlur={formik.handleBlur}
            label={"Base rate per year(%)"}
            // value={formik.values.baseRatePerYear}
            value={formik.values.baseRatePerYear}
            min={0}
            type="text"
            maxLength={4}
            placeholder={"Enter Value here"}
            name="baseRatePerYear"
            error={
              formik.touched.baseRatePerYear && formik.errors.baseRatePerYear
            }
          />
          <InputCustom
            // onChange={formik.handleChange}
            onChange={(e: any) => handleAmountchange(e, "multiplierPerYear")}
            onBlur={formik.handleBlur}
            label={"Multiplier Per Year(%)"}
            value={formik.values.multiplierPerYear}
            min={0}
            type="text"
            maxLength={5}
            placeholder={"Enter Value here"}
            name="multiplierPerYear"
            error={
              formik.touched.multiplierPerYear &&
              formik.errors.multiplierPerYear
            }
          />
          {selectVal === "JRM" && (
            <>
              <InputCustom
                // onChange={formik.handleChange}
                onChange={(e: any) =>
                  handleAmountchange(e, "jumpMultiplierPerYear")
                }
                onBlur={formik.handleBlur}
                label={"Jump Multiplier Per Year(%)"}
                value={formik.values.jumpMultiplierPerYear}
                min={0}
                type="text"
                maxLength={5}
                placeholder={"Enter Value here"}
                name="jumpMultiplierPerYear"
                error={
                  formik.touched.jumpMultiplierPerYear &&
                  formik.errors.jumpMultiplierPerYear
                }
              />
              <InputCustom
                // onChange={formik.handleChange}
                onChange={(e: any) => handleAmountchange(e, "kink")}
                onBlur={formik.handleBlur}
                value={formik.values.kink}
                min={0}
                type="text"
                maxLength={5}
                placeholder={"Enter Value here"}
                label={"Kink (%)"}
                name="kink"
                error={formik.touched.kink && formik.errors.kink}
              />
            </>
          )}

          <div className="btn_pair">
            <Button type="submit">Confirm</Button>
            <Button onClick={handleClose} className="border_btn">
              Cancel
            </Button>
          </div>
        </form>
      </CommonModal>

      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default InterestRateModal;
