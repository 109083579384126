import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import { HeaderProps } from "../common/adminHeader/AdminHeader";
import "./Sidebar.scss";
import { ROUTES } from "../../utils/Utils";

const Sidebar = ({ handleToggle, show }: HeaderProps) => {
  return (
    <aside className={`sidebar ${show ? "active" : ""}`}>
      <Link to={"/"} className="logo">
        <img src={logo} alt="logo" />
      </Link>

      <ul>
        <li>
          <NavLink onClick={handleToggle} to={ROUTES.ADMIN_DASHBOARD}>
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink onClick={handleToggle} to={ROUTES.ADMIN_TRANSACTION}>
            Transaction History
          </NavLink>
        </li>
        <li>
          <NavLink onClick={handleToggle} to={ROUTES.CURRENCY_LIST}>
            Currencies
          </NavLink>
        </li>
        <li>
          <NavLink onClick={handleToggle} to={ROUTES.BORROWER_LIST}>
            Liquidate Borrowers
          </NavLink>
        </li>
        <li>
          <NavLink onClick={handleToggle} to={ROUTES.SETTING}>
            Settings
          </NavLink>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
