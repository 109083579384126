import { Dispatch } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { callContractGetMethod } from "../../../redux/Actions/contract.action";
import {
  divideBigNumber,
  divideBigNumberWithSuffixes,
  intToSuffixes,
} from "../../../services/common.service";
import { getAccountDetails } from "../../../services/contract.service";
import { RootState } from "../../../Types/reduxStateType/RootState";
import BorrowMarket from "./borrowMarket/BorrowMarket";
import "./Dashboard.scss";
import SupplyMarket from "./supplyMarket/SupplyMarket";

const Dashboard = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { walletAddress } = useSelector((state: RootState) => state.user);
  const usdDecimals = useSelector(
    (state: RootState) => state.token.usdDecimals
  );
  const [borrowInfo, setBorrowInfo] = useState<any>();
  const [borrowBalance, setBorrowBalance] = useState<any>(0);
  const [borrowBalanceUsed, setBorrowBalanceUsed] = useState<any>();
  const [supplyBalance, setSupplyBalance] = useState<any>(0);
  const [netApy, setNetApy] = useState<any>(0);

  const { borrowRemaining, borrowUsed, borrowLimitUsed } = useMemo(() => {
    const borrowRemaining = Number(
      divideBigNumber(borrowInfo?.[1] - borrowInfo?.[2], usdDecimals)
    );

    const borrowUsed = Number(divideBigNumber(borrowBalanceUsed, usdDecimals));

    const borrowLimitUsed = (borrowUsed / (borrowRemaining + borrowUsed)) * 100;

    return { borrowRemaining, borrowUsed, borrowLimitUsed };
  }, [borrowBalanceUsed, borrowInfo, usdDecimals, walletAddress]);

  const getAccountLiquidityInfo = useCallback(async () => {
    try {
      if (walletAddress) {
        const result: any = await dispatch(
          callContractGetMethod(
            "getAccountLiquidity",
            [walletAddress],
            "comptroller",
            false
          )
        );
        console.log('result--Account Liquidity', result)
        // 0. error
        // 1. available borrow limit
        // 2. shortfall
        setBorrowInfo(result);
      }
    } catch (error) {
      console.log("Errror occurred in getting account liquidity", error);
    }
  }, [dispatch, walletAddress]);

  const getAllMarket = useCallback(async () => {
    try {
      const result: any = await dispatch(
        callContractGetMethod("getAllMarkets", [], "comptroller", false)
      );
      if (result) {
        let supplyBalance: any = await getAccountDetails(
          result,
          "balanceOfUnderlying",
          "supplyRatePerBlock"
        );
        // let totalSupplyApy = await calculateTotalApy(supplyBalance);
        let totalSupplyBalance = supplyBalance?.reduce(
          (acc: any, obj: any) => acc + obj.balanceInUsd,
          0
        );
        let totalSupplyApy = supplyBalance?.reduce(
          (acc: any, obj: any) => (obj.balanceInUsd > 0 ? acc + obj.apy : acc),
          0
        );
        setSupplyBalance(totalSupplyBalance);
        let borrowBalance: any = await getAccountDetails(
          result,
          "borrowBalanceCurrent",
          "borrowRatePerBlock"
        );
        // console.log("borrowBalance", borrowBalance);
        // let totalBorrowApy = await calculateTotalApy(borrowBalance);
        let totalBorrowBalance = borrowBalance?.reduce(
          (acc: any, obj: any) => acc + obj.balanceInUsd,
          0
        );
        // console.log("totalBorrowBalance", totalBorrowBalance);

        let totalBorrowApy = borrowBalance?.reduce(
          (acc: any, obj: any) => (obj.balanceInUsd > 0 ? acc + obj.apy : acc),
          0
        );
        // console.log("totalBorrowApy", totalBorrowApy);
        setBorrowBalance(totalBorrowBalance);
        let borrowBalanceUsed: any = await getAccountDetails(
          result,
          "borrowBalanceStored",
          ""
        );
        borrowBalanceUsed = borrowBalanceUsed?.reduce(
          (acc: any, obj: any) => acc + obj.balanceInUsd,
          0
        );
        setBorrowBalanceUsed(Number(borrowBalanceUsed));
        calculateNetApy(
          totalSupplyBalance,
          totalSupplyApy,
          totalBorrowBalance,
          totalBorrowApy
        );
      }
    } catch (error) {
      console.log("Errror occurred in getAllMarket", error);
    }
  }, [dispatch, walletAddress]);

  // const calculateTotalApy = async (balance: any) => {
  //   try {
  //     const result: any = balance?.map((item: any) => {
  //       // console.log("item.balanceInUsd", item);
  //       // console.log(" apy[index]", apy[index]);
  //       // console.log("first", item.balanceInUsd * apy[index]);
  //       return item.balanceInUsd * item.apy;
  //     });
  //     return result;
  //   } catch (error) {
  //     console.log("Errror occurred in calculateNetApy", error);
  //   }
  // };

  const calculateNetApy = async (
    totalSupplyBalance: any = 0,
    totalSupplyApy: any = 0,
    totalBorrrowBalance: any = 0,
    totalBorrowApy: any = 0
  ) => {
    try {
      // console.log("totalSupplyApy", divideBigNumber(totalSupplyApy, 18));
      // console.log("totalBorrowApy", totalBorrowApy);
      // console.log("totalSupplyBalance", totalSupplyBalance);
      // console.log("totalBorrrowBalance", totalBorrrowBalance);

      let netSupplyBalance =
        divideBigNumber(totalSupplyBalance, 18) * totalSupplyApy;

      let netBorrowBalance =
        divideBigNumber(totalBorrrowBalance, 18) * totalBorrowApy;
      // console.log("netBorrowBalance", netBorrowBalance);
      // console.log("netSupplyBalance", netSupplyBalance);
      // console.log("netBorrowBalance", netBorrowBalance);

      // console.log(
      //   "difference",
      //   Number(netSupplyBalance) - Number(netBorrowBalance)
      // );
      // console.log(
      //   "divideBigNumber(totalSupplyBalance, 18),",
      //   divideBigNumber(totalSupplyBalance, 18)
      // );
      // let weightedSupplyAPY = handleBigNumbers(
      //   totalSupplyApy,
      //   totalSupplyBalance,
      //   "div"
      // );
      // let weightedBorrowAPY =
      //   Number(totalBorrrowBalance) !== 0
      //     ? handleBigNumbers(totalBorrowApy, totalBorrrowBalance, "div")
      //     : 0;
      let netApy: any = 0;

      netApy =
        (Number(netSupplyBalance) - Number(netBorrowBalance)) /
        Number(divideBigNumber(totalSupplyBalance, 18));
      // console.log("netApy", netApy);
      // console.log("netApy", netApy);
      // console.log("weightedSupplyAPY", weightedSupplyAPY);
      // console.log("weightedBorrowAPY", weightedBorrowAPY);

      // if (Number(netBalance) > 0) {
      //   // Supply APY minus Borrow APY, weighted by total values
      //   netApy = handleBigNumbers(
      //     handleBigNumbers(
      //       handleBigNumbers(weightedSupplyAPY ?? 0, totalSupplyBalance, "mul"),
      //       handleBigNumbers(
      //         weightedBorrowAPY ?? 0,
      //         totalBorrrowBalance,
      //         "mul"
      //       ),
      //       "sub"
      //     ),
      //     netBalance,
      //     "div"
      //   );
      // } else {
      //   // If the user is in debt, net APY would be negative
      //   netApy = Number(weightedBorrowAPY) - Number(weightedSupplyAPY);
      // }

      setNetApy(Number(netApy));
    } catch (error) {
      console.log("Errror occurred in calculateNetApy", error);
    }
  };

  useEffect(() => {
    getAccountLiquidityInfo();
    getAllMarket();
  }, [getAccountLiquidityInfo, getAllMarket]);

  return (
    <>
      <div className="dashboard">
        <Container>
          <div className="dashboard_in">
            <div className="dashboard_in_head">
            
              <div className="inner_box">
                <div className="head_inner">
                  <p className="purple"> Supply Balance</p>
                  {supplyBalance ? (
                    <h3>
                      $
                      {
                        divideBigNumberWithSuffixes(
                          supplyBalance,
                          usdDecimals,
                          true
                        ).split(".")[0]
                      }
                      {divideBigNumberWithSuffixes(
                        supplyBalance,
                        usdDecimals,
                        true
                      ).split(".")[1]
                        ? "."
                        : ""}
                      <span>
                        {divideBigNumberWithSuffixes(
                          supplyBalance,
                          usdDecimals,
                          true
                        ).split(".")[1] ?? ""}
                      </span>
                    </h3>
                  ) : (
                    <h3>$0</h3>
                  )}
                </div>
                <div className="head_inner">
                  <div className={netApy < 0 ? "blue_round_box " : "round_box"}>
                    <div className="inner_round">
                      <h4>Net APY</h4>
                      <h5>{netApy ? `${netApy?.toFixed(2)}%` : "--"}</h5>
                    </div>
                  </div>
                </div>
                <div className="head_inner">
                  <p className="blue"> Borrow Balance</p>
                  {borrowBalance ? (
                    <h3>
                      $
                      {
                        divideBigNumberWithSuffixes(
                          borrowBalance,
                          usdDecimals,
                          true
                        ).split(".")[0]
                      }
                      .
                      <span>
                        {divideBigNumberWithSuffixes(
                          borrowBalance,
                          usdDecimals,
                          true
                        ).split(".")[1] ?? "00"}
                      </span>
                    </h3>
                  ) : (
                    <h3>$0</h3>
                  )}
                </div>
              </div>
              <div className="progress_box">
                <h3>
                  Borrow Limit
                  <span>
                    {borrowLimitUsed ? borrowLimitUsed.toFixed(2) : 0}%
                  </span>
                </h3>
                <div className="progress">
                  <span
                    style={{
                      width: `${borrowLimitUsed ? borrowLimitUsed : 0}%`,
                    }}
                  ></span>
                </div>
                <h3>
                  $
                  {borrowRemaining + borrowUsed
                    ? intToSuffixes(borrowRemaining + borrowUsed)
                    : 0}
                </h3>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="dashboard_content">
        <Container>
          <Row>
            <Col lg={6}>
              {supplyBalance ? (
                <div className="content_table_box">
                  <SupplyMarket
                    borrowLimitUsed={borrowLimitUsed}
                    borrowRemaining={borrowRemaining}
                    borrowUsed={borrowUsed}
                    isUser
                  />
                  <h2>All Markets</h2>
                </div>
              ) : (
                ""
              )}
              <div className="content_table_box">
                <SupplyMarket
                  borrowLimitUsed={borrowLimitUsed}
                  borrowRemaining={borrowRemaining}
                  borrowUsed={borrowUsed}
                />
              </div>
            </Col>
            <Col lg={6}>
              {borrowBalance ? (
                <div className="content_table_box">
                  <BorrowMarket
                    borrowLimitUsed={borrowLimitUsed}
                    borrowRemaining={borrowRemaining}
                    borrowUsed={borrowUsed}
                    borrowBalance={borrowBalance}
                    isUser
                  />
                  <h2>All Markets</h2>
                </div>
              ) : (
                ""
              )}
              <div className="content_table_box">
                <BorrowMarket
                  borrowLimitUsed={borrowLimitUsed}
                  borrowRemaining={borrowRemaining}
                  borrowUsed={borrowUsed}
                  borrowBalance={borrowBalance}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
