import { Outlet } from "react-router-dom";
import "./MainLayout.scss";
import AdminHeader from "../../common/adminHeader/AdminHeader";
import Sidebar from "../../sidebar/Sidebar";
import { useState } from "react";

const MainLayout = () => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    window.innerWidth < 1200 && setOpen(!open);
  };
  return (
    <div className="main_layout">
      <AdminHeader show={open} handleToggle={handleToggle} />
      <Sidebar show={open} handleToggle={handleToggle} />
      <main className="main_layout_in">
        <Outlet />
      </main>
    </div>
  );
};

export default MainLayout;
