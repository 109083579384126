// export const API_HOST = "http://10.10.2.73:5050/";
export const API_HOST = "https://stage-api.collatix.com/";
export const RPC_URL =
  "https://polygon-amoy.blockpi.network/v1/rpc/c528166e784c3c12fcb30a8ab847734d9a131f4b";
export const CHAIN_ID = 80002;
export const NETWORK_NAME = "Polygon Amoy";
export const CURRENCY = "POL";
export const NETWORK_DECIMALS = 18;
export const EXPLORER_LINK = "https://amoy.polygonscan.com/";

// export const RPC_URL =
//   "https://bsc-testnet.blockpi.network/v1/rpc/94ae482e572ad23780d660501f96c70fb3e61f18";
// export const CHAIN_ID = 97;
// export const NETWORK_NAME = "Bsc Testnet";
// export const CURRENCY = "BNB";
// export const NETWORK_DECIMALS = 18;
// export const EXPLORER_LINK = "https://testnet.bscscan.com/";

export const COMPTROLLER_ADDRESS = "0x7C24D649fC875B2F66cE0b812852E9Cde93e80dd";
export const PRICE_ORACLE_ADDRESS =
  "0x62AD71dbB9b01EE7737DE4144DB5Be8D11488aEa";
export const FACTORY_ADDRESS = "0xEc3e72472A7959050eD0eBEcF249a6f1afbb67aD";

export const SECRET_KEY1 = "],k%.XA!]S!bwD2}2NL[]$";
export const SECRET_KEY2 = "2)h@R_Cy}gpy@^{BXnKbPw";
export const SECRET_KEY3 = "u8fDtq4TidnivB5bqCxk5y";
export const SECRET_KEY4 = "ekRkiLnaBaet8mBDrhDk3Ufj";
export const LIMIT = 10;
export const LENGTH = 128;
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const PROJECT_ID = "551b3056632250352203d9e6d1178ae3";
