import { Dispatch } from "@reduxjs/toolkit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import { callContractSendMethod } from "../../../../redux/Actions/contract.action";
import { modalPropTypes } from "../../../../Types/componentPropTypes/modalPropType";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import Button from "../../button/Button";
import Checkbox from "../../checkbox/Checkbox";
import CommonModal from "../../commonModal/CommonModal";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";
import "./EnableAsCollateralModal.scss";
import toaster from "../../toast";
import { getFailureErrorMsg, intToSuffixes } from "../../../../services/common.service";

const EnableAsCollateralModal = ({
  item,
  borrowLimitUsed,
  borrowRemaining,
  borrowUsed,
  callback,
}: modalPropTypes) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [show, setShow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");
  const onHide = () => {
    setShow(false);
  };

  const enableCollateral = async () => {
    setShowConfirmation(true);
    setModalKey("enable");
    onHide();
    const provider = await connector?.getProvider();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "enterMarkets",
        [[item?.xToken]],
        walletAddress,
        "comptroller",
        "enable"
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        toaster.error(errorMSg);
        setModalKey("error");
      }
      setTimeout(() => callback && callback(), 5000);
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  const disableCollateral = async () => {
    setShowConfirmation(true);
    setModalKey("disable");
    setShow(false);
    const provider = await connector?.getProvider();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "exitMarket",
        [item?.xToken],
        walletAddress,
        "comptroller",
        "disable"
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        toaster.error(errorMSg);
        setModalKey("error");
      }
      setTimeout(() => callback && callback(), 5000);
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  return (
    <>
      {item?.collateralFactor ? (
        <Checkbox
          className="checkbox"
          onChange={() => {
            setShow(true);
          }}
          checked={item?.collateralInfo?.isEnable || false}
        />
      ) : null}
      <CommonModal
        show={show}
        onHide={onHide}
        title={`${
          item?.collateralInfo?.isEnable
            ? "Disable as Collateral"
            : "Enable as Collateral"
        }`}
        className="enable_collateral"
      >
        <div className="enable_collateral_in">
          {item?.collateralInfo?.isEnable ? (
            <p>
              This asset will no longer be used towards your borrowing limit,
              and can't be seized in liquidation. <Link to="">Learn More.</Link>
            </p>
          ) : (
            <p>
              Each assets used as collateral increases your borrowing limit. Be
              careful, this can subject the asset to being seized in
              liquidation. <Link to="">Learn More.</Link>
            </p>
          )}
          <div className="in_content">
            <ul>
              <li>
                <h4>Borrow Limit</h4>
                <h5 title={(borrowRemaining + borrowUsed)}>${intToSuffixes(borrowRemaining + borrowUsed)}</h5>
              </li>
              <li>
                <h4>Borrow Limit Used</h4>
                <h5>{borrowLimitUsed ? borrowLimitUsed.toFixed(2) : 0}%</h5>
              </li>
            </ul>
            <div className="progress_line">
              <span
                style={{
                  width: `${borrowLimitUsed ? borrowLimitUsed : 0}%`,
                }}
              ></span>
            </div>
          </div>

          {item.collateralInfo?.isEnable ? (
            <Button fluid onClick={disableCollateral} disabled={!walletAddress}>
              Disable {item?.symbol}
            </Button>
          ) : (
            <Button fluid onClick={enableCollateral} disabled={!walletAddress}>
              Use {item?.symbol} as Collateral
            </Button>
          )}
          {!walletAddress && (
            <h4 className="warning-text">Please connect wallet to continue.</h4>
          )}
        </div>
      </CommonModal>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={showConfirmation}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default EnableAsCollateralModal;
