import { createSlice } from "@reduxjs/toolkit";

/**ICO SLICE */
const initialState: any = {
  usdDecimals: 18,
  xTokenDecimals: 8,
};

export const TokenSlice = createSlice({
  name: "token",
  initialState: initialState,
  reducers: {
    setUsdDecimals: (state, param) => {
      const { payload } = param;
      state.usdDecimals = payload;
    },
    setXTokenDecimals: (state, param) => {
      const { payload } = param;
      state.xTokenDecimals = payload;
    },
  },
});

// ACTIONS FOR SLICE
export const { setUsdDecimals,setXTokenDecimals } = TokenSlice.actions;
