import { useFormik } from "formik";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import * as Yup from "yup";
import { callContractSendMethod } from "../../../../redux/Actions/contract.action";
import {
  allowOnlyNumberWithDecimalsInput,
  convertWithDecimal,
  divideBigNumber,
  divideBigNumberWithSuffixes,
  getFailureErrorMsg,
} from "../../../../services/common.service";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import Button from "../../button/Button";
import InputCustom from "../../commonInputs/InputCustom";
import CommonModal from "../../commonModal/CommonModal";
import { default as toast, default as toaster } from "../../toast";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";
import "./SetCollateralFactorModal.scss";
interface propTypes {
  data?: any;
  title?: string;
}
const SetCollateralFactorModal = ({ data, title }: propTypes) => {
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [show, setShow] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");

  const handleAmountchange = (e: any, updateName: string) => {
    let value = e.target.value;
    if (value.startsWith(".")) {
      value = `0${value}`;
    }
    
    let valid = allowOnlyNumberWithDecimalsInput(value, 2);
    
    valid &&
      formik.setValues({
        ...formik.values,
        [updateName]: value,
      });
  };

  const formik = useFormik({
    initialValues: {
      tokenValue: 0,
    },
    validationSchema: Yup.object({
      tokenValue: Yup.number()
        .required("Required Field*")
        .min(0, "Value should not be lesser than 0"),
    }),
    onSubmit: (values) => {
      if (title === "Update Borrow Limit") {
        setMarketBorrowCap(data?.xToken, values.tokenValue);
      } else if (title === "Update Collateral Factor") {
        setCollateralFactor(data?.xToken, values.tokenValue);
      } else if (title === "Update Reserve Factor") {
        setReserveFactor(data?.xToken, values.tokenValue);
      }
    },
  });




useEffect(()=>{
  if (title === "Update Borrow Limit") {
    formik.setValues({
      ...formik.values,
      tokenValue: data.borrowCaps
      ? Number(Math.trunc(divideBigNumber(
        data.borrowCaps,
          String(data?.decimals).length - 1 || 1
        )))
      : 0    
    });
    
  } else if (title === "Update Collateral Factor") {
    formik.setValues({
      ...formik.values,
      tokenValue:divideBigNumberWithSuffixes(
        data.markets,
        16,
        true
      )})
  } else if (title === "Update Reserve Factor") {
    formik.setValues({
      ...formik.values,
      tokenValue:divideBigNumberWithSuffixes(data.reserveFactor, 16, true)
    })
  }
},[show])

  const setCollateralFactor = async (
    _xAdd: string,
    _tokenVal: number | string
  ) => {
    let provider = await connector?.getProvider();
    if (typeof _tokenVal === "number" && _tokenVal > 100) {
      toaster.error("Value should be lesser than 100%");
      return;
    }

    setShowConfirmation(true);
    setModalKey("collateralFactor");
    handleClose();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setCollateralFactor",
        [_xAdd, convertWithDecimal(_tokenVal, 16)],
        walletAddress,
        "comptroller",
        "collateralFactor"
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        toaster.error(errorMSg);
        setModalKey("error");
      } else {
        toast.success("Transaction Successfull");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  const setMarketBorrowCap = async (
    _xAdd: string,
    _tokenVal: number | string
  ) => {
    let provider = await connector?.getProvider();
   
    setShowConfirmation(true);
    setModalKey("borrowCap");
    handleClose();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setMarketBorrowCaps",
        [
          [_xAdd],
          [
            convertWithDecimal(
              _tokenVal,
              String(data?.decimals).length - 1 || 1
            ),
          ],
        ],
        walletAddress,
        "comptroller",
        "borrowCap"
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        toaster.error(errorMSg);
        setModalKey("error");
      } else {
        toast.success("Transaction Successfull");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  const setReserveFactor = async (
    _xAdd: string,
    _tokenVal: number | string
  ) => {
    if (typeof _tokenVal === "number" && _tokenVal > 100) {
      toaster.error("Value should be lesser than 100 %");
      return;
    }

    let provider = await connector?.getProvider();

    setShowConfirmation(true);
    setModalKey("reserveFactor");
    handleClose();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setReserveFactor",
        [convertWithDecimal(_tokenVal, 16)],
        walletAddress,
        "cerc20",
        "reserveFactor",
        "",
        _xAdd
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        toaster.error(errorMSg);
        setModalKey("error");
      } else {
        toast.success("Transaction Successfull");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };


  const handleClose = () => {
    setShow(false);
    formik.resetForm();
  };

  return (
    <>
      <Button className="setCollateralBtn" onClick={() => setShow(true)}>
        Update
      </Button>

      <CommonModal
        show={show}
        onHide={handleClose}
        title={title}
        className="confirm_logout"
      >
        {/* <h4>{props.content}</h4> */}
        <form onSubmit={formik.handleSubmit}>
          <InputCustom
            // onChange={formik.handleChange}
            onChange={(e: any) => handleAmountchange(e, "tokenValue")}
            onBlur={formik.handleBlur}
            value={formik.values.tokenValue}
            type="text"
            min={0}
            // maxLength={25}
            placeholder={"Enter Value here"}
            name="tokenValue"
            error={formik.errors.tokenValue}
          />

          <div className="btn_pair">
            <Button type="submit">Confirm</Button>
            <Button onClick={handleClose} className="border_btn">
              Cancel
            </Button>
          </div>
        </form>
      </CommonModal>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default SetCollateralFactorModal;
