import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../Types/reduxStateType/RootState";

/**AUTHGAURD FOR INNER PAGES */
export const LoginGaurd = (props: any) => {
  const admin = useSelector((state: RootState) => state?.admin?.isAdmin);
  // return !admin ? props?.children: props?.children ;
  return  !admin ? props?.children: <Navigate to="/admin/dashboard" /> ;
};
