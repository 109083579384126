import { Dispatch } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiGetMethod } from "../../../../redux/Actions/api.action";
import {
  divideBigNumber,
  intToSuffixes,
} from "../../../../services/common.service";
import { getTokenDetails } from "../../../../services/contract.service";
import { marketPropTypes } from "../../../../Types/componentPropTypes/marketPropType";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import { TABLE_LIMIT } from "../../../../utils/Utils";
import CommonTable from "../../../common/commonTable/CommonTable";
import BorrowRepayModal from "../../../common/modals/borrowRepayModal/BorrowRepayModal";
import Shimmer from "../../../common/shimmer/Shimmer";

const BorrowMarket = ({
  borrowLimitUsed,
  borrowRemaining,
  borrowUsed,
  borrowBalance,
  isUser,
}: marketPropTypes) => {
  const dispatch: Dispatch<any> = useDispatch();
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const usdDecimals = useSelector(
    (state: RootState) => state.token.usdDecimals
  );
  const [borrowMarket, setBorrowMarket] = useState<any>([]);
  // const [totalPage, setTotalPage] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalData, setTotalData] = useState<any>(0);
  const fields1 = [
    { label: "Asset" },
    { label: "APY" },
    { label: "Wallet" },
    { label: "Liquidity" },
  ];
  const fields2 = [
    { label: "Asset" },
    { label: "APY/Accrued" },
    { label: "Balance" },
    { label: "% of Limit" },
  ];

  const getBorrowMarketDetails = useCallback(
    async (page: number = 1) => {
      try {
        let obj: {
          page: number;
          limit?: number;
          userAddress?: string;
          marketType?: string;
        } = {
          page: page,
          limit: TABLE_LIMIT,
        };
        if (walletAddress) {
          obj.userAddress = walletAddress;
        }
        if (isUser) {
          obj.marketType = "Borrow";
        }
        let result: any = await dispatch(
          callApiGetMethod(
            isUser ? "GET_USER_MARKET_LIST" : "GET_BORROW_MARKET_LIST",
            obj,
            false
          )
        );

        setBorrowMarket(result?.data?.data);
        // setTotalPage(result?.data?.totalPages);
        setCurrentPage(result?.data?.currentPage);
        // setTotalData(result?.data?.totalRecords);
        let data = await getTokenDetails(
          result?.data?.data,
          "borrowRatePerBlock"
        );

        setBorrowMarket(data);
      } catch (error) {
        console.log("Error occured in getting borrow market", error);
      }
    },
    [walletAddress, dispatch, isUser]
  );

  useEffect(() => {
    getBorrowMarketDetails();
  }, [getBorrowMarketDetails]);

  // const onPageChanged = useCallback(
  //   (event: any, page: number) => {
  //     event.preventDefault();
  //     getBorrowMarketDetails(page);
  //     window.scrollTo(0, 0);
  //   },
  //   [getBorrowMarketDetails]
  // );

  return (
    <div className="tablebox borrowing_table">
      <CommonTable
        fields={isUser ? fields2 : fields1}
        tableTitle={isUser ? "Borrowing" : "Borrow Markets"}
      >
        {borrowMarket?.map((item: any) => (
          <tr key={item?._id}>
            <td>
              <BorrowRepayModal
                item={item}
                borrowLimitUsed={borrowLimitUsed}
                borrowRemaining={borrowRemaining}
                borrowUsed={borrowUsed}
                borrowBalance={borrowBalance}
                callback={() => getBorrowMarketDetails(currentPage)}
              />
            </td>
            <td title={item?.apy}>
              {item?.apy ? (
                <>{parseFloat(item?.apy).toFixed(2)}%</>
              ) : (
                <Shimmer width={45} height={20} />
              )}
            </td>
            {isUser ? (
              <td
                title={String(
                  item?.borrowBalance
                    ? `$${divideBigNumber(
                        item?.borrowBalance[0]?.balanceInUsd ?? "0",
                        usdDecimals
                      )}`
                    : 0
                )}
              >
                $
                {item?.borrowBalance ? (
                  intToSuffixes(
                    divideBigNumber(
                      item?.borrowBalance[0]?.balanceInUsd ?? "0",
                      usdDecimals
                    ),
                    2
                  )
                ) : (
                  <Shimmer width={45} height={20} />
                )}{" "}
                <p>
                  {item?.borrowBalance ? (
                    intToSuffixes(
                      divideBigNumber(
                        item?.borrowBalance[0]?.balance ?? "0",
                        String(item?.decimals).length - 1 || 1
                      ),
                      5
                    )
                  ) : (
                    <Shimmer width={45} height={20} />
                  )}{" "}
                  {item?.symbol}
                </p>
              </td>
            ) : (
              <td
                title={`${String(
                  divideBigNumber(
                    item?.userBalance ?? "0",
                    String(item?.decimals).length - 1 || 1
                  )
                )}  ${item?.symbol}`}
              >
                {item?.userBalance ? (
                  intToSuffixes(
                    divideBigNumber(
                      item?.userBalance ?? "0",
                      String(item?.decimals).length - 1 || 1
                    ),
                    5
                  )
                ) : (
                  <Shimmer width={45} height={20} />
                )}{" "}
                {item?.userBalance ? item?.symbol : null}
              </td>
            )}
            {/* For use progress bar in table */}
            {isUser ? (
              <td>
                <div className="borrowing_progress_box">
                  <div className="progress">
                    <span
                      style={{
                        width: `${
                          item?.borrowBalance
                            ? (divideBigNumber(
                                item?.borrowBalance[0]?.balanceInUsd ?? "0",
                                usdDecimals
                              ) /
                                (borrowRemaining + borrowUsed)) *
                              100
                            : 0
                        }%`,
                      }}
                    ></span>
                  </div>
                  <h2>
                    {item?.borrowBalance
                      ? (
                          (divideBigNumber(
                            item?.borrowBalance[0]?.balanceInUsd ?? "0",
                            usdDecimals
                          ) /
                            (borrowRemaining + borrowUsed)) *
                          100
                        ).toFixed(2)
                      : 0}
                    %
                  </h2>
                </div>
              </td>
            ) : (
              <td>
                {item?.liquidity !== undefined ? (
                  item?.liquidity
                ) : (
                  <Shimmer width={45} height={20} />
                )}
              </td>
            )}
          </tr>
        ))}
      </CommonTable>
    </div>
  );
};

export default BorrowMarket;
