import { useSelector } from "react-redux";
import { RootState } from "../Types/reduxStateType/RootState";
import { Navigate } from "react-router-dom";

/**AUTHGAURD FOR INNER PAGES */
export const RequireAuth = (props: any) => {
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
    const admin = useSelector((state: RootState) => state?.admin?.isAdmin);

      // return walletAddress && admin ? props?.children: props?.children ;
    return walletAddress && admin ? props?.children : <Navigate to="/admin/login" />;

};
