export * as Yup from "yup";

export const ROUTES = {
  ADMIN_DASHBOARD: "/admin/dashboard",
  TRANSACTION: "/transaction",
  ADMIN_TRANSACTION: "/admin/transaction",
  SETTING: "/admin/setting",
  CURRENCY_LIST: "/admin/currency-list",
  BORROWER_LIST: "/admin/borrower-list",
  LOGIN: "/admin/login",
};

export const SOCIAL_MEDIA = {
  TELEGRAM: "https://www.telegram.com",
  FACEBOOK: "https://www.facebook.com",
  YOUTUBE: "https://www.youtube.com",
  TWITTER: "https://twitter.com/",
};

export const APIURL: any = {
  GET_SUPPLY_MARKET_LIST: "token/getSupplyMarketTokens",
  GET_BORROW_MARKET_LIST: "token/getBorrowMarketTokens",
  GET_TRANSACTION_LIST:"transactions/getTransactions",
  GET_TOKEN_INFO : "token/getTokenInfo",
  GET_USER_MARKET_LIST: "transactions/getUserMarketList",
  GET_DASHBOARD_DETIAL:"admin/getDashboardData",
  GET_INTERST_RATE:"token/getInterestRates",
  GET_BORROW_INFO:"transactions/getBorrowInfo",
  GET_BORROW_LIST:"transactions/getLiquidateBorrowerList",  
  GET_ALL_TOKENS : "token/getTokens",
  GET_X_TOKEN_INFO:"token/getXTokenInfo",
};

export const RESPONSES: any = {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NOCONTENT: 204,
  BADREQUEST: 400,
  UN_AUTHORIZED: 401,
  INVALID_REQ: 422,
  FORBIDDEN: 403,
  NOTFOUND: 404,
  TIMEOUT: 408,
  TOOMANYREQ: 429,
  INTERNALSERVER: 500,
  BADGATEWAYS: 502,
  SERVICEUNAVILABLE: 503,
  GATEWAYTIMEOUT: 504,
};

export const DATE_FORMAT = "DD-MM-YYYY hh:mm A";

export const TABLE_LIMIT = 10;