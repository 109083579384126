import { Outlet } from "react-router-dom";
import DashboardHeader from "../../common/dashboardHeader/DashboardHeader";
import "./AuthLayout.scss";

const AuthLayout = () => {

  return (
    <main className="auth_layout">
      <DashboardHeader />
      <Outlet />
    </main>
  );
};

export default AuthLayout;
