import { ReactNode } from "react";
import Modal from "react-bootstrap/Modal";
import { CloseIcon } from "../../../assets/icons/Icons";
import "./CommonModal.scss";

const CommonModal = ({
    show,
    onHide,
    children,
    className,
    title,
    subTitle,
    backdrop,
    icon,
    backdropClassName,
    size
}: {
    show?: boolean;
    onHide?: any;
    children?: ReactNode;
    className?: string;
    title?: string | ReactNode;
    subTitle?: string | ReactNode;
    closeButton?: any;
    backdrop?: any;
    icon?: any;
    backdropClassName?: string,
    size?:any
}) => {
    return (
        <Modal
            backdrop={backdrop}
            show={show}
            onHide={onHide}
            centered
            backdropClassName={backdropClassName}
            className={`custom-modal ${className ? className : ""}`}
            size={size??"md"}
        >

            {(title || subTitle) && (
                <div className="custom-modal__header">
                    <h2 className="custom-modal__title">{icon ? <span>{icon}</span> : null}{title}</h2>
                    <button onClick={onHide} className="custom-modal__action">
                        <CloseIcon />
                    </button>
                </div>

            )}
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
};

export default CommonModal;
