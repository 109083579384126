import { Dispatch, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  ApprovedIcon,
  RequestIcon,
  TransactionsIcon
} from "../../../assets/icons/Icons";
import { callApiGetMethod } from "../../../redux/Actions/api.action";
import "../../common/adminHeader/AdminHeader.scss";
import CommonHeading from "../../common/commonHeading/CommonHeading";
import "./AdminDashboard.scss";
import DashboardCard from "./dashboardCard/DashboardCard";

const AdminDashboard = () => {
  const [eventCount, setEventCount] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [totalUser, setTotalUser] = useState<any>();
  const dispatch: Dispatch<any> = useDispatch();
 
  const getDashboardDetail = async () => {
    try {
      let result: any = await dispatch(
        callApiGetMethod("GET_DASHBOARD_DETIAL", {}, false)
      );
      if (result?.success) {
        setTotalCount(result?.data?.getTransactionsCount.totalCount);
        setTotalUser(result?.data?.totalusers);
        setEventCount(result?.data?.getTransactionsCount?.eventCounts);
      }
    } catch (error) {
      console.log("Error occured in getting token information", error);
    }
  };
  useEffect(() => {
    getDashboardDetail();
  }, []);

  const getEventTitle = (_event: string) => {
    switch (_event) {
      case "Mint":
        return "Supply";
      case "RepayBorrow":
        return "Repay";
      case "Borrow":
        return "Borrow";
        case "Redeem":
        return "Withdraw";
      default:
        return "";
    }
  };
  return (
    <>
      <div className="admin_dashboard">
        <CommonHeading title="Dashboard" />
        <div className="admin_dashboard_in">
          <Row>
            <Col xxl={3} lg={4} md={6} sm={6}>
              <DashboardCard
                icon={<RequestIcon />}
                title={"Total User"}
                subTitle={totalUser}
              />
            </Col>
          </Row>
        </div>

        <div className="admin_dashboard_in">
          <h1>Transactions</h1>
          <Row>
            <Col xxl={3} lg={4} md={6} sm={6}>
              <DashboardCard
                icon={<TransactionsIcon />}
                title={"Total Transactions"}
                subTitle={totalCount}
              />
            </Col>
            {eventCount.length > 0 &&
              eventCount?.map((data: any, index: number) => {
                return (
                  <Col xxl={3} lg={4} md={6} sm={6} key={index}>
                    <DashboardCard
                      icon={<ApprovedIcon />}
                      title={getEventTitle(data._id)}
                      subTitle={data?.count}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
